<template>
  <div>
    <br>
    <h5>Problem Description</h5>
    <p class="big mt-2">
      Two triangles are said to be congruent to each other is they can be superimposed on each other through rotation, inversion or translation.
      <br>
      In this MagicGraph, you will learn to examine the congruence of two triangles.
    </p>
    <div style="text-align:left">
      <h5> MagicGraph | Area of a Parallelogram</h5>
    </div>
    <p class="big mt-2">
      Use the MagicGraph below to examine the congruence of two triangles. Tap on <i class="next ma-1" /> button to move over to the next question.
      Tap on <i class="previous ma-1" /> button to go to previous step.
      <br>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <br>
    <h5> Navigation Icons </h5>
    <p class="big mt-2">
      This MagicGraph contains a number of navigation icons/buttons to help you navigate through different parts of the exercise. See below for an explanation of each navigation icons.<br>
      <i class="redoer ma-1" /> is the 'Next' button. Tap on this button to move over to the next question. <br>
      <i class="tester ma-1" /> is the 'Check Your Answer' button. Tap on this button to check your answer.<br>
      <i class="logor ma-1" /> is the 'Get a Hint' button. Tap on this button to receive a hint on how to solve this problem. <br>
      <i class="undoer ma-1" /> is the 'Start Over' button. Tap on this button to start over the exercise from the begining.
    </p>
    <h5> Marks & Indications </h5>
    <p class="big mt-2">
      The marks and indications are located on the top-left corner of the MagicGraph, and show your attempt status.<br>
      The right checkmark <i class="righter ma-1" />  indicates that your provided response was correct. <br>
      The crossmark <i class="wrong ma-1" />  indicates that your provided response was incorrect. <br>
      The exclaimation mark<i class="missed ma-1" /> indicates an unattempted (missed) response. <br>
    </p>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Congruence',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Congruent Triangles';
    this.$store.commit('navigation/changeTitle', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Congruent Triangles',
          titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Learn interactively about congruences of triangles'}
                ]
        }
   },
}
</script>
