import {
  createSpace,
  createAxes,
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
//  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  drawPoint,
//  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  drawTriangle,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  setSize,
  labelIt,
  drawSegment,
  drawDash,
  place3Circles,
  place3BCircles,
  placeEggs,
  writeSmallText
} from '../Utils.js';
const Boxes = {
box1: function () {
      ///////////////////////////////////GLOBAL SETTINGS//////////////////////
  JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.polygon.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  //////////////////////////////////LOCAL SETTINGS////////////////////////////
  var graph =createSpace(-8,8,-7,9);
  graph.suspendUpdate();
  graph.options.layer['image']=26;
  graph.options.layer['text']=2;
  graph.options.layer['html']=2;
  graph.options.layer['line']=6;
  graph.options.layer['point']=10;
  graph.options.layer['polygon']=4;
  graph.options.image.highlight=false;
//    graph.options.text.display='internal';
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
//    var ax =createAxes(graph);
//    ax[0].setAttribute({ticks:false});
//    ax[1].setAttribute({ticks:false});
///////////////////////////////
  var i = 0; var xList=[-5, -7, 0];
  var eggs = placeEggs(graph);
  eggs[0].setAttribute({visible:()=>i>=0});
////////////////
  eggs[1].setAttribute({visible:()=>i>=1});
////////////////
  eggs[2].setAttribute({visible:()=>i>=2});
////////////////////////////////////////////
placeTitle(graph, 'Congruent Triangles', '(Are two triangles congruent?)');
////////////////////////////////////////////////////////////////////////////////
    var next = placeRedo(graph, 'right');
    hoverMe(graph, next, 'Forward', 0, -5);
    ////////////////////////////////////////////////////
    var prev = placeUndo(graph, 'left');
    hoverMe(graph, prev, 'Backward', 0, -5);
/////////////////////////////////////////////////////////////////////////////
    var li =graph.create('line', [[0,0],[0,1]],{visible:false});
    /////////////////////////////////////////////////////////
    var Pt1 = drawPoint(graph, -2-4, 0);
    setSize(graph, Pt1, 0);
    var Pt2 = drawPoint(graph, ()=>xList[i], 4);
    setSize(graph, Pt2, 0);
    var Pt3 = drawPoint(graph, 3-4, 0);
    setSize(graph, Pt3, 0);
    /////////////////////////////////
    var tri =drawTriangle(graph, Pt1, Pt2, Pt3);
    tri.setAttribute({borders:{visible:true, dash:1}});
    /////////////////////
    var trnf1=graph.create('transform', [-2.*Math.PI/3], {type: 'rotate'});
    var trnf2=graph.create('transform', [4, 3], {type: 'translate'});
    var trnf3=graph.create('transform',[li],{type:'reflect'});
    var tranf=[trnf1];
    var St1 =drawPoint(graph, -2-4, 0);
    setSize(graph, St1, 0);
    var St2 =drawPoint(graph, -5, 4);
    setSize(graph, St2, 0);
    var St3 =drawPoint(graph, 3-4, 0);
    setSize(graph, St3, 0);
    var Tt1 =drawPoint(graph, -2-4, 0);
    setSize(graph, Tt1, 0);
    var Tt2 =drawPoint(graph, -7, 4);
    setSize(graph, Tt2, 0);
    var Tt3 =drawPoint(graph, 3-4, 0);
    setSize(graph, Tt3, 0);
    var Rt1 =drawPoint(graph, -2-4, 0);
    setSize(graph, Rt1, 0);
    var Rt2 =drawPoint(graph, 0, 4);
    setSize(graph, Rt2, 0);
    var Rt3 =drawPoint(graph, 3-4, 0);
    setSize(graph, Rt3, 0);
    ////////////////////////////
    writeSmallText(graph, positionX+2.5, -2.5, 'Drag the slider to examine the congruence of the two triangles.');
    var tri2 = drawTriangle(graph, St1, St2, St3);
    tri2.setAttribute({fillColor:'red', visible:()=>i==0})
    var text2 = placeMiddleText(graph, positionX, 5.5, 'The two triangles are related by rotation.');
    text2.setAttribute({visible:()=>i==0});
    /////////////////////////////////////////////
    var tri3 = drawTriangle(graph, Tt1, Tt2, Tt3);
    tri3.setAttribute({fillColor:'green', visible:()=>i==1});
    var text3 = placeMiddleText(graph, positionX, 5.5, 'The two triangles are related by translation.');
    text3.setAttribute({visible:()=>i==1});
    ///////////////////////////////////////////
    var tri4 = drawTriangle(graph, Rt1, Rt2, Rt3);
    tri4.setAttribute({fillColor:'orange',visible:()=>i==2});
    var text4 = placeMiddleText(graph, positionX, 5.5, 'The two triangles are related by reflection.');
    text4.setAttribute({visible:()=>i==2});
    ////////////////////////////////////////////////////
    var theta =placeSlider(graph, 2, -4, -2*Math.PI/3, -2*Math.PI/3, 0, 2, 'DragMe');
    theta.label.setAttribute({visible:false});
    var trnf4=graph.create('transform', [function(){return theta.Value();}], {type: 'rotate'});
    trnf4.bindTo([St1,St2,St3]);

    var trnf5=graph.create('transform', [0, function(){return 2.4*theta.Value();}], {type: 'translate'});
    trnf5.bindTo([Tt1,Tt2,Tt3]);

    trnf3.applyOnce([Rt1, Rt2, Rt3]);

    var trnf6=graph.create('transform', [function(){return 12-12*(1+3*theta.Value()/2/Math.PI);}, 0], {type: 'translate'});
    var trnf7=graph.create('transform', [function(){return 0-0*(1+3*theta.Value()/2/Math.PI);}, 0], {type: 'translate'});
    var trnf8=graph.create('transform', [function(){return 2-2*(1+3*theta.Value()/2/Math.PI);}, 0], {type: 'translate'});
     trnf6.bindTo([Rt1]);
     trnf7.bindTo([Rt2]);
     trnf8.bindTo([Rt3]);
    ////////////////////////////////////////////////
    next.on('down', function(){
      if(i==0){
      //  trnf1.applyOnce([St1,St2,St3]);
        theta.moveTo([2, -4]);
      }
      if(i==1){
      //  trnf2.applyOnce([St1,St2,St3]);
        theta.moveTo([2, -4]);
      }
      if(i==2){
      //  trnf3.applyOnce([St1,St2,St3]);
        theta.moveTo([2, -4]);
      }
      if(i<2){
      i+=1;}
    });
    prev.on('down', function(){
      if(i==2){
      theta.moveTo([2, -4]);
      }
      if(i==1){
      theta.moveTo([2, -4]);
      }
      if(i>0){
      i-=1;
    }
    });
///////////////////////////////////////////////////////////////////////
    graph.unsuspendUpdate();
                      },
              }
export default Boxes;
